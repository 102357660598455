import React, { useState, useEffect } from "react";
import {Row, Col} from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import * as echarts from "echarts";

const PieChartComponent = () => {
    const [selectedOption, setSelectedOption] = useState("total");

    useEffect(() => {
        const chartDom = document.getElementById("chart");
        const myChart = echarts.init(chartDom);

        // Definir los datos para cada opción
        const dataOptions = {
            total: [
                { value: 20, name: 'Critical' },
                { value: 30, name: 'High' },
                { value: 50, name: 'Medium' },
                { value: 100, name: 'Low' }
            ],
            tenable: [   
                { value: 15, name: 'Critical' },
                { value: 5, name: 'High' },
                { value: 38, name: 'Medium' },
                { value: 60, name: 'Low' }
            ],
            prisma: [
                { value: 5, name: 'Critical' },
                { value: 25, name: 'High' },
                { value: 12, name: 'Medium' },
                { value: 40, name: 'Low' }
            ],
        };

        // Configuración del gráfico
        const option = {
            legend: {
                top: 'top',
            },
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    type: 'pie',
                    radius: '50%',
                    center: ['50%', '50%'],
                    data: dataOptions[selectedOption],  // Datos dinámicos basados en la selección
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    label: {
                        formatter: '{b}: {c} ({d}%)'
                    }
                }
            ]
        };

        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [selectedOption]);  // El gráfico se actualiza cuando cambia la opción seleccionada

    return (
        <div>
            <select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                style={{ marginBottom: "20px" }}
            >
                <option value="total">Total</option>
                <option value="tenable">Tenable</option>
                <option value="prisma">Prisma</option>
            </select>

            <div id="chart" style={{ width: "600px", height: "400px" }}></div>
        </div>
    );
};

const InformacionVulnerabilidades = () => {
    const initialData = [
        {
            clasificacion: 'Critical',
            cantidad: 20,
            porcentaje: '10%'
        },
        {
            clasificacion: 'High',
            cantidad: 30,
            porcentaje: '15%'
        },
        {
            clasificacion: 'Medium',
            cantidad: 50,
            porcentaje: '25%'
        },
        {
            clasificacion: 'Low',
            cantidad: 100,
            porcentaje: '50%'
        },
    ];
    const [activo, setActivo] = useState(initialData);

    return (
        <div>
            <br/>
            <br/>
            <Row>
                <Col sm={6} xs={12}>
                    <TableContainer>
                        <Table
                            stickyHeader
                            size={"medium"}
                            maxHeightBody="55vh"
                        >
                            <TableHead >
                                <TableRow>
                                    <TableCell style = {{backgroundColor:'#000000', color: 'white'}}>Impacto Cualitativo</TableCell>
                                    <TableCell style = {{backgroundColor:'#000000', color: 'white'}}>Cantidad</TableCell>
                                    <TableCell style = {{backgroundColor:'#000000', color: 'white'}}>Porcentaje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activo.map((row, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                        >
                                            <TableCell style = {{backgroundColor:'#F2F2F2'}}>{row.clasificacion}</TableCell>
                                            <TableCell style = {{backgroundColor:'#F2F2F2'}}>{row.cantidad}</TableCell>
                                            <TableCell style = {{backgroundColor:'#F2F2F2'}}>{row.porcentaje}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
                <Col sm={6} xs={12}>
                    <PieChartComponent />
                </Col>
            </Row>
        </div>
    );
}

export default InformacionVulnerabilidades;