import React, { useEffect, useState } from 'react';
import Nueva_evaluacion from './Nueva_evaluacion';
import EditarEvaluacion from './EditarEvaluacion';

function EvaluacionesPadre() {
    const [EditarEvalm, setEditarEvalm] = useState("");
    
    useEffect(() => {
        const EditarEvalmVal = parseInt(localStorage.getItem("idcompania"), 10);
        
        if (EditarEvalmVal === 1) {
            setEditarEvalm("Nueva");
        }
        else {
            setEditarEvalm("Vieja");
        }
    }, []);

    return (
        <div>
            {EditarEvalm === "Nueva" ? (
                <EditarEvaluacion/>
            )
            : EditarEvalm === "Vieja" ? (
                <Nueva_evaluacion/>
            )
            : (
                <div></div>
            )}
        </div>
    );
}
export default EvaluacionesPadre;