import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const StackedLineInherenteChart = ({ historicoinherente, getColor }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (historicoinherente.length === 0) return; 

    if (!chart) {
      const chartDom = document.getElementById("main_inherente_chart");
      const myChart = echarts.init(chartDom);
      setChart(myChart);
    }

    if (chart) {
      const seriesData = historicoinherente.map((item, index) => ({
        name: `Riesgo ID :${item.idriesgo}`,
        type: "line",
        data: item.exposicioninherentep95,
        lineStyle: {
          color: getColor(index),
        },
        itemStyle: {
          color: getColor(index),
        },
      }));

      const dates = historicoinherente[0].fechaCreacion.map((date) =>
        new Date(date).toLocaleDateString()
      );

      const option = {
        title: {
          text: "Histórico Riesgo Inherente",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: seriesData.map((item) => item.name),
          top: 30,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dates,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData,
      };

      chart.setOption(option);
    }

    return () => {
      chart && chart.dispose();
    };
  }, [historicoinherente, chart]);

  return (
    <div
      id="main_inherente_chart"
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default StackedLineInherenteChart;
