import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col, Modal, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { he } from "date-fns/locale";

import PropTypes from "prop-types";

const headCells = [
  {
    id: "id_riesgo",
    num: "10",
    align: "center",
    disablePadding: false,
    label: "Id Riesgo",
  },
  {
    id: "evento",
    num: "0",
    align: "left",
    disablePadding: false,
    label: "Evento",
  },
  {
    id: "descripcion",
    num: "1",
    align: "center",
    col: 3,
    disablePadding: false,
    label: "Descripción Evento Riesgo",
  },
  {
    id: "riesgo_inherente",
    num: "2",
    align: "center",
    disablePadding: true,
    label: "Riesgo Inherente",
  },
  {
    id: "efectividad_res",
    num: "7",
    align: "center",
    disablePadding: true,
    label: "Efectividad Control",
  },
  {
    id: "efectividad_manual",
    num: "7",
    align: "center",
    disablePadding: true,
    label: "Efectividad de control Manual",
  },
  {
    id: "riesgo_residual",
    num: "3",
    align: "center",
    disablePadding: true,
    label: "Riesgo Residual",
  },
  {
    id: "nivel_riesgo",
    num: "8",
    align: "center",
    disablePadding: true,
    label: "Nivel de Riesgo",
  },
  {
    id: "p50Inherente",
    num: "4",
    align: "center",
    disablePadding: true,
    label: "P50 Inherente",
  },
  {
    id: "p99Inherente",
    num: "5",
    align: "center",
    disablePadding: true,
    label: "P99 Inherente",
  },
  {
    id: "estado_riesgo",
    num: "6",
    align: "center",
    disablePadding: true,
    label: "Estado del Riesgo",
  },
  {
    id: "justificacion",
    num: "7",
    align: "left",
    col: 3,
    disablePadding: false,
    label: "Justificación Estado Riesgo",
  },
  {
    id: "decision",
    num: "9",
    align: "center",
    disablePadding: true,
    label: "Decisión",
  },
];

const headCellsDatosExcel = [
  {
    id: "fuente",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Fuente",
  },
  {
    id: "evento_amenaza",
    align: "center",
    col: 2,
    disablePadding: true,
    label: "Evento De Amenaza",
  },
  {
    id: "factor_exp",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Factor de Exposición",
  },
  {
    id: "vector_imp",
    align: "left",
    col: 1,
    disablePadding: true,
    label: "Vector de Impacto",
  },
  {
    id: "frecuencia",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Frecuencia",
  },
  {
    id: "frecuenciaModelo",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Frecuencia de Modelo",
  },
  {
    id: "efectividad_control",
    align: "center",
    col: 1,
    disablePadding: false,
    label: "Efectividad Control",
  },
];

const headCellsDatosValoracion = [
  {
    id: "idefecto",
    align: "center",
    disablePadding: true,
    label: "Id Efecto",
  },
  {
    id: "nombreefecto",
    align: "center",
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "tipoefecto",
    align: "center",
    disablePadding: true,
    label: "Tipo Efecto",
  },
  {
    id: "resultado_p50",
    align: "center",
    disablePadding: false,
    label: "P50(MM)",
  },
  {
    id: "resultado_p95",
    align: "center",
    disablePadding: true,
    label: "P95(MM)",
  },
  {
    id: "resultado_p99",
    align: "center",
    disablePadding: true,
    label: "P99(MM)",
  },
  {
    id: "metodovaloracion",
    align: "center",
    disablePadding: true,
    label: "Método",
  },
  { id: "analista", align: "center", disablePadding: true, label: "Analista" },
];

function EnhancedTableHead(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ backgroundColor: "#2c2a29", color: "#ffffff" }} />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
            colSpan={headCell.col ? headCell.col : 1}
          >
            <label className="label" style={{ marginRight: "20%" }}>
              {headCell.label}
            </label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

function EnhancedTableHeadDatosExcel(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellsDatosExcel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
            colSpan={headCell.col}
          >
            <label>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadDatosExcel.propTypes = {
  classes: PropTypes.object.isRequired,
};

function EnhancedTableHeadValoracion(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellsDatosValoracion.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <label className="texto">{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadValoracion.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function DetalleRiesgos() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const classes = useStyles();

  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
  });

  const classesRow = useStyles();
  const [detalladoRiesgos, setDetalladoRiesgos] = useState([]);
  const [dataRiesgo, setDataRiesgo] = useState();
  const [dataRiesgos, setDataRiesgos] = useState([]);

  useEffect(() => {
    const fetchdataDetalles = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/riesgos/detalle/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let riesgosTemp = [];
        let riesgosDetail = [];
        let riesgosDetailTemp = [];
        let riesgosFinal = [];
        let amenazaxActor = [];
        let actores = [];
        let actoresFinal = [];
        let data = await result.json();
        for (let i = 0; i < data.length; i++) {
          if (!riesgosTemp.includes(data[i].idriesgo)) {
            riesgosTemp.push(data[i].idriesgo);
          }
        }
        for (let i = 0; i < riesgosTemp.length; i++) {
          actores = [];
          for (let j = 0; j < data.length; j++) {
            if (riesgosTemp[i] == data[j].idriesgo) {
              if (!actores.includes(data[j].idactor)) {
                actores.push(data[j].idactor);
              }
            }
          }
          actoresFinal.push(actores);
        }
        for (let i = 0; i < riesgosTemp.length; i++) {
          for (let j = 0; j < actoresFinal[i].length; j++) {
            amenazaxActor = [];
            for (let k = 0; k < data.length; k++) {
              if (riesgosTemp[i] == data[k].idriesgo) {
                if (actoresFinal[i][j] == data[k].idactor) {
                  amenazaxActor.push(data[k]);
                }
              }
            }
            actoresFinal[i][j] = amenazaxActor;
          }
        }
        setDetalladoRiesgos(actoresFinal);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    fetchdataDetalles();
  }, []);

  return (
    <div>
      <br></br>

      <h1 className="label">Resumen evento de Amenaza</h1>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced tableExcel"
        >
          <EnhancedTableHeadDatosExcel />
          <TableBody>
            {detalladoRiesgos.map((evento) => {
              return (
                <>
                  {dataRiesgo == undefined ? (
                    " "
                  ) : (
                    <>
                      {evento.map((actor, index) => {
                        return (
                          <>
                            {actor[0].idriesgo == dataRiesgo.idriesgo ? (
                              <>
                                <TableRow
                                  key={actor[0].idriesgo}
                                  className={classesRow.root}
                                >
                                  <TableCell component="th" scope="row">
                                    {actor[0].fuente}/{actor[0].actor}
                                  </TableCell>
                                  <TableCell colSpan={2}>
                                    {actor.map((detalle) => (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {detalle.evento_amenaza}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </TableCell>
                                  <TableCell>
                                    {actor.map((detalle) => (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {parseFloat(
                                              detalle.factorexposicion
                                            ).toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </TableCell>
                                  <TableCell>
                                    {actor.map((detalle) => (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {parseFloat(
                                              detalle.vectorimpacto
                                            ).toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </TableCell>
                                  <TableCell>
                                    {actor.map((detalle) => (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {parseFloat(
                                              detalle.frecuenciasoc
                                            ).toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </TableCell>
                                  <TableCell>
                                    {actor.map((detalle) => (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {parseFloat(
                                              detalle.frecuenciamodelo
                                            ).toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </TableCell>
                                  <TableCell>
                                    {actor.map((detalle) => (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {parseFloat(
                                              detalle.efectividadcontroles
                                            ).toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </TableCell>
                                </TableRow>
                                <hr></hr>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DetalleRiesgos;
