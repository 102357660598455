import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { Row, Col, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import StackedLineEfectividadChart from "./StackedLineEfectividadChart.js";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Para el análisis de brechas no hay controles no implementados para
          mostrar o no son adherentes a ninguna amenaza
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

const useStyles = makeStyles({
  container: {
    maxHeight: "55vh",
    minHeight: "55vh",
  },
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  head: {
    backgroundColor: "#2c2a29",
    color: "White",
  },
});

function ResumenEfectividad() {
  const [dataResumenRiesgo, setDataResumenRiesgo] = useState([]);
  const [dataHistoricoEfectividad, setDataHistoricoEfectividad] = useState([]);
  const classes = useStyles();

  const getColor = (index) => {
    const colors = [
      "#00C587",
      "#9F62D2",
      "#01CDEB",
      "#FF803A",
      "#2C2A29",
      "#FFB8D2",
    ];
    return colors[index % colors.length];
  };

  const getCapaData = (detalle, amenaza, capa) => {
    const amenazaObj = detalle.find((item) =>
      Object.keys(item).includes(amenaza)
    );

    if (amenazaObj) {
      return amenazaObj[amenaza]?.[capa] || null;
    }

    return null;
  };

  useEffect(() => {
    const fetchDataRiesgo = async () => {
      try {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/controlesNew/resumenefectividad/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );
        const datafetchDataRiesgo = await result.json();

        setDataResumenRiesgo(datafetchDataRiesgo);

        const historicoEfectividad = Object.entries(datafetchDataRiesgo).map(
          ([idriesgo, riesgo]) => ({
            idriesgo,
            historico: riesgo.historico_efectividad || [],
          })
        );

        setDataHistoricoEfectividad(historicoEfectividad);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };
    fetchDataRiesgo();
  }, []);

  function orderRows(data) {
    const amenazasOb = Object.keys(data[0])
      .filter((key) => key !== "efectividad_riesgo")
      .filter((key) => key !== "historico_efectividad");

    return amenazasOb;
  }

  function orderColumns(data) {
    const columnasCapasOb = Array.from(
      new Set(
        data.flatMap((obj) =>
          Object.keys(obj)
            .filter((key) => key !== "efectividad_riesgo")
            .filter((key) => key !== "historico_efectividad")
            .flatMap((key) =>
              Object.keys(obj[key]).filter(
                (subKey) => subKey !== "efectividad_amenaza"
              )
            )
        )
      )
    );

    const columnas = ["Amenaza", ...columnasCapasOb, "Efectividad Amenaza"];
    return columnas;
  }

  function orderColumnsA(data) {
    const columnasCapasOb = Array.from(
      new Set(
        data.flatMap((obj) =>
          Object.keys(obj)
            .filter((key) => key !== "efectividad_riesgo")
            .filter((key) => key !== "historico_efectividad")
            .flatMap((key) =>
              Object.keys(obj[key]).filter(
                (subKey) => subKey !== "efectividad_amenaza"
              )
            )
        )
      )
    );

    return columnasCapasOb;
  }

  return (
    <div style={{ margin: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Resumen de efectividad de controles
      </Typography>
      <Row>
        <Col sm={8} xs={12}>
          {Object.entries(dataResumenRiesgo).map(([id, riesgo], index) => (
            <Accordion key={id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ order: -1 }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6">
                  <strong>ID Riesgo:</strong> {id}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <strong>Efectividad de Controles Actual:</strong>{" "}
                      {riesgo.efectividad_riesgo}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <strong>Cantidad de controles implementados:</strong>{" "}
                      {riesgo.cantidad_implementados}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <strong>Cantidad de controles no implementados:</strong>{" "}
                      {riesgo.cantidad_no_implementados}
                    </Typography>
                  </Grid>
                </Grid>
                <br />
                <Typography variant="h6">Detalle de controles</Typography>
                <Row>
                  <Paper
                    variant="outlined"
                    className={classes.paper}
                    style={{ maxWidth: "100%", overflowX: "auto" }}
                  >
                    <TableContainer style={{ maxHeight: "54vh" }}>
                      <Table
                        className={classes.table}
                        aria-label="enhanced table"
                        aria-labelledby="tableTitle"
                        size="medium"
                        stickyheader
                        style={{ position: "relative" }}
                      >
                        <TableHead style={{ top: "0", position: "sticky" }}>
                          <TableRow>
                            {orderColumns(riesgo.detalle).map(
                              (column, index) => (
                                <TableCell className={classes.head} key={index}>
                                  {column}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderRows(riesgo.detalle).map((amenaza, index) =>
                            amenaza !== "efectividad_riesgo" ? (
                              <TableRow key={index}>
                                <TableCell>{amenaza}</TableCell>
                                {orderColumnsA(riesgo.detalle).map(
                                  (capa, i) => {
                                    const capaData = getCapaData(
                                      riesgo.detalle,
                                      amenaza,
                                      capa
                                    );
                                    return (
                                      <TableCell key={i}>
                                        {capaData &&
                                        capaData.controles_no_implementados !==
                                          undefined ? (
                                          <div>
                                            <p>
                                              Controles no implementados:{" "}
                                              {
                                                capaData.controles_no_implementados
                                              }
                                            </p>
                                            <ul>
                                              {capaData.nombres_no_imp.map(
                                                (nombre, j) => (
                                                  <li key={j}>{nombre}</li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        ) : (
                                          "No Aplica"
                                        )}
                                      </TableCell>
                                    );
                                  }
                                )}
                                <TableCell>
                                  {riesgo.detalle?.[0]?.[amenaza]
                                    ?.efectividad_amenaza ?? ""}
                                </TableCell>
                              </TableRow>
                            ) : null
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Row>
                <hr />
                <br />
                <Typography variant="h6">Histórico</Typography>
                <Row>
                  <Col sm={12} xs={12}>
                    <TableContainer style={{ maxHeight: "54vh" }}>
                      <Table
                        className={classes.table}
                        aria-label="enhanced table"
                        aria-labelledby="tableTitle"
                        size="medium"
                        stickyheader
                        style={{ position: "relative" }}
                      >
                        <TableHead style={{ top: "0", position: "sticky" }}>
                          <TableRow>
                            <TableCell className={classes.head}>
                              Efectividad Control
                            </TableCell>
                            <TableCell className={classes.head}>
                              Estado ERO
                            </TableCell>
                            <TableCell className={classes.head}>
                              Validador ERO
                            </TableCell>
                            <TableCell className={classes.head}>
                              Fecha
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {riesgo.historico_efectividad.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.efectividad}</TableCell>
                              <TableCell>{row.estado_flujo}</TableCell>
                              <TableCell>
                                {"Responsable de" + row.validadorero}
                              </TableCell>
                              <TableCell>{row.fechacreacion}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
                <hr />
                <br />
              </AccordionDetails>
            </Accordion>
          ))}
        </Col>
        <Col sm={4} xs={12}>
          <StackedLineEfectividadChart
            historicoefectividad={
              dataHistoricoEfectividad ? dataHistoricoEfectividad : []
            }
            getColor={getColor}
            tipo={"resumenefectividad"}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ResumenEfectividad;
