import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { lighten } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, TextField } from "@mui/material";
import { Tooltip, List, ListItem, ListItemText } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import {
  Check,
  Clear,
  ChevronLeft,
  ChevronRight,
  FilterList,
  FirstPage,
  LastPage,
  Search,
  ArrowDownward,
  ViewColumn,
} from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import styled from "@mui/material/styles/styled";
import { tableCellClasses } from "@mui/material/TableCell";
import DomainChartComponent from "./DomainChartComponent.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function LineasBaseEro() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "auto",
      overflowY: "auto",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },

    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));
  const classes = useStyles();
  const [filter, setFilter] = useState(false);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState([]);

  const handleClick = (event, row) => {
    setSelected(selected === row.name ? null : row.idlinea_base);
    setOpen(true);
  };

  const isSelected = (idlinea_base) => selected === idlinea_base;

  const handleClose = () => {
    setOpen(false);
    setRowData([]);
  };
  const handleChange = () => {
    setFilter(!filter);
  };

  const [informacion, setLineasBase] = useState([]);
  const [dominioschart, setDominiosChart] = useState([]);


  useEffect(() => {
    const fetchLineasBase = async () => {
      try {
        const result = await fetch(
          `${process.env.REACT_APP_API_URL}evaluacion/lineasbase/`
          + `${localStorage.getItem("idactivo")}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );
        const data = await result.json();
        setLineasBase(data['info']);
        setDominiosChart(data['dominios']);
      } catch (error) {
        console.error("Error al obtener las líneas base:", error);
      }
    };
    fetchLineasBase();
  }, []);

  return (
    <div>
      <br />
      <hr />
      <Row>
        <Col sm={4} xs={12}>
          <label className="label forn-label">Cumplimiento por LB</label>
        </Col>
      </Row>
      <Row>
        <Col sm={4} xs={12}>
          <label className="label forn-label">Cumplimiento total</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            defaultValue={""}
          ></input>
        </Col>
        <Col sm={4} xs={12}>
          <label className="label forn-label">Efectividad capa</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            defaultValue={""}
          ></input>
        </Col>
      </Row>
      <Row>
        <Col sm={4} xs={12}>
          <label className="label forn-label">Cumplimiento por dominios</label>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12}>
          {dominioschart.length > 0 && (
            <DomainChartComponent dominios={dominioschart} />
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12}>
          <label className="label forn-label">
            Recomendaciones y Controles
          </label>
        </Col>
        <Col>
          <TableContainer component={Paper} className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              maxHeightBody="55vh"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow className={classes.head}>
                  <StyledTableCell>Línea Base</StyledTableCell>
                  <StyledTableCell>Dominio</StyledTableCell>
                  <StyledTableCell>Recomendacion/Lineamiento</StyledTableCell>
                  <StyledTableCell>¿Cumplió?</StyledTableCell>
                  <StyledTableCell>Id Control CIS</StyledTableCell>
                  <StyledTableCell>Control CIS</StyledTableCell>
                  <StyledTableCell>Version Control</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {informacion.map((row) => {
                  return Object.keys(row?.detalle_cis.Domain || {}).map(
                    (domainKey) => {
                      const recommendations =
                        row.detalle_cis.Domain[domainKey]?.Recommendation || {};
                      return Object.keys(recommendations).map(
                        (recommendationKey) => {
                          return recommendations[recommendationKey].map(
                            (recommendation, index) => {
                              const cumple = Math.random() < 0.5;
                              return (
                                <StyledTableRow
                                  hover
                                  onClick={(event) => handleClick(event, row)}
                                  tabIndex={-1}
                                  key={`${row.idlinea_base}-${domainKey}-${recommendationKey}-${index}`}
                                >
                                  <StyledTableCell>{row.nombrecis}</StyledTableCell>
                                  <StyledTableCell>{domainKey}</StyledTableCell>
                                  <StyledTableCell>
                                    {recommendationKey}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <span
                                      style={{
                                        color: cumple ? "green" : "red",
                                      }}
                                    >
                                      {cumple ? "Si" : "No"}
                                    </span>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {recommendation.ControlIdCIS}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {recommendation["Control Name"]}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {recommendation["Version Control"]}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          );
                        }
                      );
                    }
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
      <br />
      <hr />
      <br />
    </div>
  );
}
export default LineasBaseEro;
