import AADService from "../../funcionesAuth.js";

export const obtenerCompanias = async () => {
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const result = await fetch(
    process.env.REACT_APP_API_URL + "general/compania/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  let datCompania = await result.json();
  return datCompania;
};
