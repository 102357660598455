import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";

import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { he } from "date-fns/locale";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// INICIO del Diseño de la tabla anidada
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// FIN del Diseño de la tabla anidada

function M_Riesgos() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const classes = useStyles();
  const [filter, setFilter] = useState(false);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const columns = [
    {
      field: "idevento_riesgo",
      title: "ID Evento Riesgo",
    },
    {
      field: "evento",
      title: "Evento",
    },
    {
      field: "caracteristica_seguridad",
      title: "Característica Seguridad",
    },
    {
      field: "disp_varchar1",
      title: "Descripción",
    },
  ];

  const [eventoriesgo, setEventoriesgo] = React.useState([]);

  useEffect(() => {
    const fetcheventoriesgo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "maestro/riesgos/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setEventoriesgo(data);
    };
    fetcheventoriesgo();
  }, []);

  const handleChange = () => {
    setFilter(!filter);
  };

  return (
    <div>
      <Row>
        <Col>
          <h1>Taxonomía de Riesgos</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ zIndex: 100 }}>
            <Paper variant="outlined" className={classes.paper}>
              <MaterialTable
                columns={columns}
                data={eventoriesgo}
                icons={tableIcons}

                // Inicio de la tabla anidada ###################################
                detailPanel={(rowData) => {
                  return (
                    <div
                    style={
                      {padding: "30px 120px 40px 120px",
                        height: "auto",
                      }
                    }>
                      <h4>Amenazas</h4>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          sx={{ minWidth: 100 }}
                          size="small"
                          aria-label="customized table"
                          stickyHeader
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Id</StyledTableCell>
                              <StyledTableCell>Evento</StyledTableCell>
                              {/* <TableCell>Caracteristica Seguridad</TableCell>
                              <TableCell>Descripcion</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                              {rowData.amenazas.map((rowData) => (
                                <StyledTableRow  key={rowData.idamenaza}>
                                  <StyledTableCell>{rowData.idamenaza}</StyledTableCell>
                                  <StyledTableCell>{rowData.evento_amenaza}</StyledTableCell>
                                </StyledTableRow >
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  );
                }}
                // Fin de la tabla anidada ##############################

                options={{
                  showTitle: false,
                  filtering: filter,
                  searchFieldAlignment: "left",
                  searchFieldStyle: { height: 50 },
                  //zIndex: -1000,
                  headerStyle: {
                    //zIndex: 1,
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "relative",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "800px",
                  selection: false,
                  disableMultipleSelection: true,
                  pageSizeOptions: [15, 20, 30],
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  pageSize: 15,
                }}
                components={{
                  Pagination: (props) => (
                    <div className={classes.pagination}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Checkbox
                            checked={filter}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Mostrar filtros por columnas
                          </Typography>
                        }
                      />
                      <TablePagination
                        {...props}
                        className={classes.pagination}
                      />
                    </div>
                  ),
                }}
                // onRowClick={(evt, selectedRow) => {
                //   handleClick(selectedRow.idimplementador);
                //   return setSelectedRow(selectedRow.tableData.id);
                // }}
                // actions={[
                //   {
                //     icon: () => (
                //       <Link to='NuevoMImplementador'>
                //         <Button className='botonPositivo'>Nuevo</Button>
                //       </Link>
                //     ),
                //     onClick: add_eval(),
                //     position: "toolbar",
                //     isFreeAction: true,
                //   },
                //   {
                //     icon: () => (
                //       <Link to='EditarMimplementador'>
                //         <Button className='botonGeneral'>Editar</Button>
                //       </Link>
                //     ),
                //     onClick: editar(),
                //     position: "toolbar",
                //     isFreeAction: true,
                //     hidden: ButtonEdit === false,
                //   },
                // ]}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
              />
            </Paper>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default M_Riesgos;
