import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { lighten } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, TextField } from "@mui/material";
import { Tooltip, List, ListItem, ListItemText } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import {
  Check,
  Clear,
  ChevronLeft,
  ChevronRight,
  FilterList,
  FirstPage,
  LastPage,
  Search,
  ArrowDownward,
  ViewColumn,
} from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import styled from "@mui/material/styles/styled";
import { tableCellClasses } from "@mui/material/TableCell";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function EditarMVulnerabilidad() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const classes = useStyles();
  const [filter, setFilter] = useState(false);
  const [valueVulnerabilidadNombre, setValueVulnerabilidadNombre] =
    useState("");
  const [valueVulnerabilidadDescripcion, setValueVulnerabilidadDescripcion] =
    useState("");
  const [
    valueVulnerabilidadDescripcionExtendida,
    setValueVulnerabilidadDescripcionExtendida,
  ] = useState("");
  const [consecuencias, setConsecuencias] = useState([]);
  const [mitigaciones, setMitigaciones] = useState([]);
  const [ejemplos, setEjemplos] = useState([]);
  const [patronesataque, setPatronesAtaque] = useState([]);
  const [linkcwe, setLinkCWE] = useState("");
  const [debilidades, setDebilidades] = useState([]);

  // Variables de estado para la tercera API de CWE
  const [nombremodal, setNombreModal] = useState("");
  const [descripcionmodal, setDescripcionModal] = useState("");
  const [descripcionextendidamodal, setDescripcionExtendidaModal] =
    useState("");
  const [nivelabstraccionmodal, setNivelAbstraccionModal] = useState("");
  const [linkcweModal, setLinkCWEModal] = useState("");
  const [ProbabilidadexplotacionModal, setProbabilidadExplotacionModal] = useState("");
  const [ejemplosobservadosModal, setEjemplosObservadosModal] = useState([]);
  const [MitigacionesModal, setMitigacionesModal] = useState([]);
  const [ConsecuenciasModal, setConsecuenciasModal] = useState([]);
  const [patronesasociados, setPatronesAsociados] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const [validated, setValidated] = useState(false);

  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState([]);

  const handleClick = (event, row) => {
    setSelected(selected === row.name ? null : row.idcwe);
    setOpen(true);
  };

  const isSelected = (idcwe) => selected === idcwe;

  const handleClose = () => {
    setOpen(false);
    setRowData([]);
  };

  useEffect(() => {
    const fetchvulnerabilidades = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "maestro/vulnerabilidades/" +
          localStorage.getItem("idcwe"),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      if (data != null && data[0].vulnerabilidad !== null) {
        setValueVulnerabilidadNombre(data[0].vulnerabilidad);
      }
      if (data != null && data[0].disp_varchar1 !== null) {
        setValueVulnerabilidadDescripcion(data[0].disp_varchar1);
      }
      if (data != null && data[0].link !== null) {
        setLinkCWE(data[0].link);
      }
      if (
        data != null &&
        data[0].cwe_xml_data[0].extended_description !== null
      ) {
        setValueVulnerabilidadDescripcionExtendida(
          data[0].cwe_xml_data[0].extended_description
        );
      }
    };

    const fetchdebilidades = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "maestro/debilidades/1/" +
          localStorage.getItem("idcwe"),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      if (data != null) {
        setDebilidades(data);
      }
    };
    fetchdebilidades();
    fetchvulnerabilidades();
  }, []);

  useEffect(() => {
    if (open && selected !== null) {
      const fetchmodal = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "maestro/vulnerabilidades/" +
            selected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let data = await result.json();
        setRowData(data);
        if (data != null && data[0].name !== null) {
          setNombreModal(data[0].name);
        }
        if (data != null && data[0].linkcweModal !== null) {
          setLinkCWEModal(data[0].linkcweModal);
        }
        if (data != null && data[0].description !== null) {
          setDescripcionModal(data[0].description);
        }
        if (data != null && data[0].extended_description !== null) {
          setDescripcionExtendidaModal(data[0].extended_description);
        }
        if (data != null && data[0].abstraction !== null) {
          setNivelAbstraccionModal(data[0].abstraction);
        }
        if (data != null && data[0].likelihood_of_exploit !== null) {
          setProbabilidadExplotacionModal(data[0].likelihood_of_exploit);
        }
        if (data != null && data[0].observed_examples !== null) {
          setEjemplosObservadosModal(data[0].observed_examples);
        }
        if (data != null && data[0].common_consequences !== null) {
          setConsecuenciasModal(data[0].common_consequences);
        }
        if (data != null && data[0].potential_mitigations !== null) {
          setMitigacionesModal(data[0].potential_mitigations);
        }
        if (data != null && data[0].related_attack_patterns !== null) {
          setPatronesAsociados(data[0].related_attack_patterns);
        }
      };
      fetchmodal();
    }
  }, [open, selected]);

  const processConsequences = (consecuencias) => {
    // Dividir las consecuencias por espacios excepto "Access Control"
    const separarpalabra = consecuencias.split(" ");
    const resultado = [];

    for (let i = 0; i < separarpalabra.length; i++) {
      if (
        separarpalabra[i] === "Access" &&
        separarpalabra[i + 1] === "Control"
      ) {
        resultado.push("Access Control");
        i++; // Saltar "Control" ya que lo hemos procesado como 'Access Control'
      } else if (separarpalabra[i] !== "") {
        resultado.push(separarpalabra[i]);
      }
    }
    return resultado;
  };

  const handleChange = () => {
    setFilter(!filter);
  };

  return (
    <div>
      <Row>
        <Col>
          <h2 className="subtitulo">Detalle Debilidad</h2>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Id CWE</label>
        </Col>
        <Col sm={1} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            defaultValue={localStorage.getItem("idcwe")}
          ></input>
        </Col>
        <Col sm={1} xs={12}>
          <label className="label forn-label">Nombre</label>
        </Col>
        <Col sm={8} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={valueVulnerabilidadNombre}
          ></input>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Descripción</label>
        </Col>
        <Col sm={10} xs={12}>
          <textarea
            style={{ resize: "none", textAlign: "left" }}
            className="form-control text-center"
            rows="3"
            disabled
            value={valueVulnerabilidadDescripcion}
            id="Descripcion"
          ></textarea>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Descripción Extendida</label>
        </Col>
        <Col sm={10} xs={12}>
          <textarea
            style={{ resize: "none", textAlign: "justify" }}
            className="form-control text-center"
            rows="10"
            disabled
            value={valueVulnerabilidadDescripcionExtendida}
            id="DescripcionExtendida"
          ></textarea>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">
            Enlace para ver más detalles
          </label>
        </Col>
        <Col sm={10} xs={12}>
          <a href={linkcwe} target="_blank" rel="noopener noreferrer">
            <input
              style={{ backgroundColor: "white", color: "blue" }}
              type="text"
              className="form-control text-center font-weight-bold"
              value="Ver más"
            ></input>
          </a>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={12} xs={12}>
          <label className="label forn-label">
            Debilidades Asociadas (CWE's)
          </label>
        </Col>
        <Col>
          <TableContainer component={Paper} className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              maxHeightBody="55vh"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow className={classes.head}>
                  <StyledTableCell padding="checkbox"></StyledTableCell>
                  <StyledTableCell>Id CWE</StyledTableCell>
                  <StyledTableCell>Nombre CWE</StyledTableCell>
                  <StyledTableCell>Descripcion</StyledTableCell>
                  <StyledTableCell>Consecuencias</StyledTableCell>
                  <StyledTableCell>Fase de Mitigacion</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {debilidades.map((row) => {
                  const isItemSelected = isSelected(row.idcwe);
                  const labelId = `enhanced-table-checkbox-${row.idcwe}`;
                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.idcwe}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.idcwe}</StyledTableCell>
                      <StyledTableCell>{row.nombrecwe}</StyledTableCell>
                      <StyledTableCell>{row.descripcion}</StyledTableCell>
                      <StyledTableCell>
                        <ul>
                          {processConsequences(row.consecuencias)
                            .filter(
                              (consecuencias) => consecuencias.trim() !== ""
                            )
                            .map((consecuencias, index) => (
                              <li key={index}>{consecuencias}</li>
                            ))}
                        </ul>
                      </StyledTableCell>
                      <StyledTableCell>{row.fase_mitigacion}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Modal
              size="xl"
              show={open}
              onHide={handleClose}
              //   dialogClassName="modal-100w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Detalles de la Debilidad
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="grid-example">
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Id CWE</label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={selected}
                    ></input>
                  </Col>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Nombre</label>
                  </Col>
                  <Col sm={6} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={nombremodal}
                    ></input>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Nivel de abstración
                    </label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={nivelabstraccionmodal}
                    ></input>
                  </Col>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Ver más detalles</label>
                  </Col>
                  <Col sm={6} xs={12}>
                    <a
                      href={linkcweModal}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <input
                        style={{ backgroundColor: "white", color: "blue" }}
                        type="text"
                        className="form-control text-center font-weight-bold"
                        value="Ver más"
                      ></input>
                    </a>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Descripción</label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="3"
                      disabled
                      value={descripcionmodal}
                      id="Descripcion"
                    ></textarea>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Descripción Extendida
                    </label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "justify" }}
                      className="form-control text-center"
                      rows="10"
                      disabled
                      value={descripcionextendidamodal}
                      id="DescripcionExtendida"
                    ></textarea>
                  </Col>
                </Row>
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Probabilidad de Explotación
                    </label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={ProbabilidadexplotacionModal}
                    ></input>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={12} xs={12}>
                    <label className="label forn-label">Consecuencias Comunes</label>
                  </Col>
                  <Col>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        maxHeightBody="55vh"
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow className={classes.head}>
                            <StyledTableCell padding="checkbox"></StyledTableCell>
                            <StyledTableCell>Consecuencias</StyledTableCell>
                            <StyledTableCell>Impacto</StyledTableCell>
                            <StyledTableCell>Notas</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ConsecuenciasModal.map((row) => (
                            <StyledTableRow key={row.Reference}>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>
                                <ul>
                                  {row.Scopes.map((scope) => (
                                    <li>{scope}</li>
                                  ))}
                                </ul>
                              </StyledTableCell>
                              <StyledTableCell>{row.Impacts}</StyledTableCell>
                              <StyledTableCell>{row.Notes}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col sm={12} xs={12}>
                    <label className="label forn-label">Mitigaciones Potenciales</label>
                  </Col>
                  <Col>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        maxHeightBody="55vh"
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow className={classes.head}>
                            <StyledTableCell padding="checkbox"></StyledTableCell>
                            <StyledTableCell>Fase</StyledTableCell>
                            <StyledTableCell>Descripción</StyledTableCell>
                            <StyledTableCell>Estrategia</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {MitigacionesModal.map((row) => (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>{row.Phase}</StyledTableCell>
                              <StyledTableCell>{row.Description}</StyledTableCell>
                              <StyledTableCell>{row.Strategy}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col sm={12} xs={12}>
                    <label className="label forn-label">Ejemplos Observados</label>
                  </Col>
                  <Col>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        maxHeightBody="55vh"
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow className={classes.head}>
                            <StyledTableCell padding="checkbox"></StyledTableCell>
                            <StyledTableCell>Referencias</StyledTableCell>
                            <StyledTableCell>Link</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ejemplosobservadosModal.map((row) => (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>{row.Reference}</StyledTableCell>
                              <StyledTableCell>
                                <a
                                  href={row.Link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Ver más detalles
                                </a>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col sm={12} xs={12}>
                    <label className="label forn-label">Amenazas asociadas</label>
                  </Col>
                  <Col>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow className={classes.head}>
                            <StyledTableCell padding="checkbox"></StyledTableCell>
                            <StyledTableCell>ID CAPEC</StyledTableCell>
                            <StyledTableCell>Link</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {patronesasociados.map((row) => (
                            <StyledTableRow>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>{row.CAPEC_ID}</StyledTableCell>
                              <StyledTableCell>
                                <a
                                  href={row.Link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Ver más detalles
                                </a>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </TableContainer>
        </Col>
      </Row>
      <hr />
    </div>
  );
}

export default EditarMVulnerabilidad;
