import React, { Component } from "react";
import { Button, Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { without } from "lodash";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import AADService from "./funcionesAuth.js"


class NavDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre_compania_ppal: "",
      companias: [],
      id_compania_ppal: 1,
    };
    this.AzureADService = new AADService();
    this.token = this.AzureADService.getToken();
  }
  componentDidMount() {
    const fetchcompanies = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      let companias = await result.json();
      let id_compania = this.state.id_compania_ppal;
      localStorage.setItem("idcompania", this.state.id_compania_ppal); // guarda el id de la copañía que hace parte del usuario
      let self = this;
      Object.keys(companias).forEach(function (key) {
        if (companias[key]["idcompania"] === id_compania) {
          let nombre_compania = companias[key]["compania"];
          let temp = companias;
          temp = without(temp, companias[key]);
          self.setState({
            nombre_compania_ppal: nombre_compania,
            companias: temp,
            id_compania_ppal: id_compania,
          });
        }
      });
    };
    fetchcompanies();
  }

  render() {
    const listcompanies = this.state.companias;
    let companias_temp = [];
    const companies = this.state.companias.map((company) => (
      <div>
      <NavDropdown.Item style={{ background:"#2c2a29" }}
        onSelect={() => {
          let elem = {
            compania: this.state.nombre_compania_ppal,
            idcompania: this.state.id_compania_ppal,
          };
          this.state.companias.push(elem);
          //this.state.companias.pop(elem)
          this.setState({ nombre_compania_ppal: company.compania });
          this.setState({ id_compania_ppal: company.idcompania });
          companias_temp = this.state.companias.filter(
            (elem) => elem.idcompania !== company.idcompania
          );
          this.setState({ companias: companias_temp });
          localStorage.setItem("idcompania", company.idcompania);
        }}
        key={company.idcompania}
      >
        <Link to="menu" style={{color:"white", background:"#2c2a29"}}>
          {company.compania}
        </Link>
      </NavDropdown.Item>
      </div>
    ));

    return (
      <div>
        <NavDropdown
          title={this.state.nombre_compania_ppal}
          id="basic-nav-dropdown"
        >
          <div>{companies}</div>
        </NavDropdown>
      </div>
    );
  }
}

export default NavDropDown;
