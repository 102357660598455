import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Eliminar_vul_tec from './Eliminar_vul_tec';
import InformacionVulnerabilidades from './InformacionVulnerabilidades';


const MainVulnerabilidades = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Gestión de vulnerabilidades" style={{ color: "#ffffff", padding: "3px 7px 3px"}}  />
                    <Tab label="Información vulnerabilidades" style={{ color: "#ffffff", padding: "3px 7px 3px"}} />
                </Tabs>
            </AppBar>
            {value === 0 && <Eliminar_vul_tec />}
            {value === 1 && <InformacionVulnerabilidades  />}
        </div>
    );
};

export default MainVulnerabilidades;

