import React, { Component, useContext, useEffect, useState } from "react";
import * as echarts from "echarts";

const MarimekkoChartComponent = () => {
    useEffect(() => {
      const chartDom = document.getElementById("main");
      const myChart = echarts.init(chartDom);
  
      const option = {
        // title: {
        //     text: 'Distribución de Tipos de Riesgo'
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Cibernético", "Tecnológico", "Contagio de terceros"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [
            "Fuga o fallas de confidencialidad",
            "Interrupción o denegación",
            "Modificación o adulteración",
          ],
        },
        series: [
          {
            name: "Cibernético",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              position: "insideRight",
            },
            itemStyle: {
              color: "#FFD204",
            },
            data: [100, 100, 100],
          },
          {
            name: "Tecnológico",
            type: "bar",
            stack: "total",
            label: {
              show: false,
              position: "insideRight",
            },
            itemStyle: {
              color: "#FFB8D2",
            },
            data: [1, 1, 1],
          },
          {
            name: "Contagio de terceros",
            type: "bar",
            stack: "total",
            label: {
              show: false,
              position: "insideRight",
            },
            itemStyle: {
              color: "#D3D3D3",
            },
            data: [1, 1, 1],
          },
        ],
      };
  
      myChart.setOption(option);
  
      return () => {
        myChart.dispose();
      };
    }, []);
  
    return (
      <div>
        <div id="main" style={{ width: "100%", height: "500px" }}></div>
      </div>
    );
  };

export default MarimekkoChartComponent;