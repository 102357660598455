import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";

import React, { useEffect, useState } from "react";
// estilos
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Checkbox } from "@mui/material";
import { CircularProgress, TablePagination } from "@material-ui/core";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { encryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import { Link, useHistory } from "react-router-dom";

//estilos tablas
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import { RoomRounded } from "@material-ui/icons";
import AADService from "../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));

//activos header
const headCells = [
  { id: "ID", numeric: false, disablePadding: true, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },

  {
    id: "compania",
    numeric: false,
    disablePadding: false,
    label: "Compañia",
  },
  {
    id: "responsable_ti",
    numeric: false,
    disablePadding: false,
    label: "Responsable TI",
  },
  {
    id: "responsable_negocio",
    numeric: false,
    disablePadding: false,
    label: "Responsable Negocio",
  },
];

//modales
const ModalCarga = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={true}
    >
      <Form
      /*           onSubmit={(e) => {
                postArchivo(e, archivo);
              }} */
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cargue Masivo de Hallazgos
            <br></br>
            <a
              href="https://bancolombia.sharepoint.com/teams/CdulaRiesgoTI-Ciber/_layouts/15/download.aspx?UniqueId=08dd2ada%2D4a89%2D4639%2D884a%2D38df406be7d9"
              target="_blank"
              rel="noopener noreferrer"
            >
              Descargar plantilla
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <input
                type="file"
                name="files"
                accept=".xlsx,.csv"
                multiple
                //onChange={(e) => subirArchivo(e.target.files)}
              ></input>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loading ? (
            <>{"404"}</>
          ) : (
            <Button
              //type='submit'
              className="botonPositivo"
              style={{ minWidth: "20%" }}
            >
              Cargar
            </Button>
          )}
          <Button onClick={props.onHide} className="botonNegativo">
            Cancelar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

//Empieza tabla de activos
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const EditarEthicalHacking = () => {
  const [modalCompo, setModalCompo] = useState([]);
  const [rows, setRows] = useState([]);
  const [validated, setValidated] = useState(false);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalOtros, setShowModalOtros] = useState(false);
  const [showModalClasificar, setShowModalClasificar] = useState(false);
  const [rowsc, setRowsC] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedInfra, setSelectedInfra] = useState([]);
  let history = useHistory();
  const idRol = parseInt(localStorage.getItem("rolusuario"));
  const [idInforme, setId] = useState("");
  const [nombreInforme, setNombreInforme] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [programa, setPrograma] = useState("");
  const [experto, setExperto] = useState("");
  const [activos, setActivos] = useState("");
  const [otros, setOtros] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const obtenerPreguntas = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "general/parametrosGeneralesxGrupoxParametro/Componentes/Clasificacion/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datPreguntas = await result.json();
      setDataPreguntas(datPreguntas);
    } else if (result.status >= 400 && result.status <= 500) {
      setDataPreguntas([]);
      return result.status;
    }
  };
  const obtenerClasificacion = async (idactivo) => {
    const result = await fetch(
      process.env.REACT_APP_API_URL + "activoClasificacion/" + idactivo + "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datClasificacion = await result.json();
      setDataClasificacion(datClasificacion[0]);
    } else if (result.status >= 400 && result.status <= 500) {
      setDataClasificacion([]);
      return result.status;
    }
  };
  const ExportarExcel = () => {
    // Datos Generales
    const idInforme =
      document.getElementById("idInforme")?.value || "No disponible";
    const nombreInforme =
      document.getElementById("NombreInforme")?.value || "No disponible";
    const descripcion =
      document.getElementById("Descripcion")?.value || "No disponible";
    const programa =
      document.getElementById("programa")?.value || "No disponible";
    const experto =
      document.getElementById("NombreExperto")?.value || "No disponible";

    const datosGenerales = [
      { Campo: "Id del informe", Valor: idInforme },
      { Campo: "Nombre del informe", Valor: nombreInforme },
      { Campo: "Descripción", Valor: descripcion },
      { Campo: "Programa", Valor: programa },
      { Campo: "Experto", Valor: experto },
    ];

    // Tabla de Aplicaciones
    const tablaAplicaciones = document.querySelectorAll(
      "#tablaAplicaciones tbody tr"
    );
    const datosAplicaciones = Array.from(tablaAplicaciones).map(
      (row, index) => {
        const celdas = row.querySelectorAll("td");
        return {
          ID: celdas[0]?.innerText || "No disponible",
          Nombre: celdas[1]?.innerText || "No disponible",
          Compañía: celdas[2]?.innerText || "No disponible",
          ResponsableTI: celdas[3]?.innerText || "No disponible",
          ResponsableNegocio: celdas[4]?.innerText || "No disponible",
          Confidencialidad: celdas[5]?.innerText || "No disponible",
          Integridad: celdas[6]?.innerText || "No disponible",
          Disponibilidad: celdas[7]?.innerText || "No disponible",
        };
      }
    );

    // Tabla de Otros
    const tablaOtros = document.querySelectorAll("#tablaOtros tbody tr");
    const datosOtros = Array.from(tablaOtros).map((row) => {
      const celdas = row.querySelectorAll("td");
      return {
        ID: celdas[0]?.innerText || "No disponible",
        Nombre: celdas[1]?.innerText || "No disponible",
        Compañía: celdas[2]?.innerText || "No disponible",
        Responsable: celdas[3]?.innerText || "No disponible",
        Confidencialidad: celdas[4]?.innerText || "No disponible",
        Integridad: celdas[5]?.innerText || "No disponible",
        Disponibilidad: celdas[6]?.innerText || "No disponible",
      };
    });

    // Crear libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Agregar hojas
    const hojaGenerales = XLSX.utils.json_to_sheet(datosGenerales);
    XLSX.utils.book_append_sheet(workbook, hojaGenerales, "Datos Generales");

    const hojaAplicaciones = XLSX.utils.json_to_sheet(datosAplicaciones);
    XLSX.utils.book_append_sheet(workbook, hojaAplicaciones, "Aplicaciones");

    const hojaOtros = XLSX.utils.json_to_sheet(datosOtros);
    XLSX.utils.book_append_sheet(workbook, hojaOtros, "Otros");

    // Exportar archivo
    XLSX.writeFile(workbook, "InformeCompleto.xlsx");
  };

  useEffect(() => {
    const ethicalhacking = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "maestro/ethicalhacking/1",

        //+ localStorage.getItem("idinformes_ethical_hacking"),

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await result.json();
      if (data && data[0]) {
        const ethicalhacking = data[0];
        setId(ethicalhacking.idinformes_ethical_hacking || "");
        setNombreInforme(ethicalhacking.nombre || []);
        setDescripcion(ethicalhacking.descripcion || "");
        setPrograma(ethicalhacking.programa || "");
        setExperto(ethicalhacking.experto || "");
        setActivos(ethicalhacking.activos || "");
        setOtros(ethicalhacking.otros || "");
      }
    };
    const fetchUsuarios = async () => {
      try {

        const url = `${process.env.REACT_APP_API_URL}usuariosrol/1/${idRol}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        });



        const usuarios = await response.json();
        setUsuarios(usuarios); // Actualiza el estado con los usuarios obtenidos
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsuarios();
    obtenerPreguntas();
    ethicalhacking();
  }, []);

  const isSelectedInfra = (name) => selectedInfra.indexOf(name) !== -1;

  const InteractiveTable = ({
    idrol,
    onSave,
    preguntas,
    dataClasificacion,
  }) => {
    console.log("InteractiveTable rendered");
    const [selectedRows, setSelectedRows] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [answersSend, setAnswersSend] = useState({});

    const handleSelectChange = (event, rowIndex, idparametrosgenerales) => {
      event.stopPropagation();

      // Actualizar estado basado en el estado actual
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [rowIndex]: event.target.value,
      }));

      setAnswersSend((prevAnswersSend) => ({
        ...prevAnswersSend,
        [idparametrosgenerales]: event.target.value === "Si" ? 1 : 0,
      }));
    };

    let globalRowIndex = 0;

    return (
      <div>
        <div
          style={{
            textAlign: "right",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="botonPositivo3"
            onClick={() => {
              console.log("Guardando:", answersSend); // Depuración
              onSave(answersSend);
            }}
          >
            {idrol === 3 || idrol === 4 || idrol === 5
              ? "Autoevaluar"
              : "Evaluar"}
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }} aria-label="interactive table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "black" }}>
                <TableCell sx={{ color: "white" }}>Atributo</TableCell>
                <TableCell sx={{ color: "white" }}>Pregunta</TableCell>
                <TableCell sx={{ color: "white" }}>Clasificación</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preguntas.map((item, itemIndex) => {
                const rowIndex = globalRowIndex++;
                return (
                  <TableRow key={`${itemIndex}-${item.vlrmetrica}`}>
                    <TableCell>{item.disp_varchar1}</TableCell>
                    <TableCell>{item.vlrmetrica}</TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <select
                        className="form-control"
                        value={answers[rowIndex] || ""}
                        onChange={(e) =>
                          handleSelectChange(
                            e,
                            rowIndex,
                            item.idparametrosgenerales
                          )
                        }
                        disabled={
                          (dataClasificacion.metodo_confidencialidad !==
                            "Por evaluación" &&
                            item.disp_varchar1 === "Confidencialidad") ||
                          (dataClasificacion.metodo_integridad !==
                            "Por evaluación" &&
                            item.disp_varchar1 === "Integridad") ||
                          (dataClasificacion.metodo_disponibilidad !==
                            "Por evaluación" &&
                            item.disp_varchar1 === "Disponibilidad")
                        }
                      >
                        <option value="" disabled>
                          Seleccione
                        </option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const handleClickInfra = (event, name) => {
    const selectedIndex = selectedInfra.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedInfra(newSelected);
  };
  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let componentePrincipal = [];
      rowsc.map((dat) => {
        componentePrincipal.push({
          idcomponentedelcomponente: 0,
          idactivo: dat.idactivo,
          idtipo_activo: dat.idtipo_activo,
          idposicionresponsablenegocio: dat.idposicionresponsablenegocio,
          idposicionresponsableti: dat.idposicionresponsableti,
          idactivocomponente: 0,
          fechacreacion: today.toISOString(),
          estadoasociacion: true,
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
          disp_numerico1: null,
          disp_numerico2: null,
          disp_varchar1: null,
          disp_varchar2: null,
        });
      });
      console.log("componente principal es", componentePrincipal);
      //Si el array de componentes principales está vacío, no se puede guardar
      if (componentePrincipal.length === 0) {
        alert("Debe seleccionar un componente principal");
        setValidated(false);
        setEstadoPost(4);
        return;
      }

      //Si nombre es vacío, no se puede guardar
      if (document.getElementById("NombreComponente").value === "") {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Si ip o dns son vacíos, no se puede guardar
      if (
        document.getElementById("IP").value === "" ||
        document.getElementById("DNS").value === ""
      ) {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Si ubicación lógica, relevancia o tipo ambiente son vacíos, no se puede guardar
      if (
        document.getElementById("Ubicacion_logica").value === "" ||
        document.getElementById("relevancia").value === "" ||
        document.getElementById("Tipo_Ambiente").value === ""
      ) {
        setValidated(false);
        setEstadoPost(4);
        return;
      }

      var data = {
        idcompania: localStorage.getItem("idcompania"),
        nombre: document.getElementById("NombreComponente").value,
        descripcion: document.getElementById("Descripcion").value,
        estado: true,
        componente_principal: 0,
        ubicacion_logica:
          document.getElementById("Ubicacion_logica") !== null
            ? document.getElementById("Ubicacion_logica").value
            : null,
        relevancia:
          document.getElementById("relevancia") !== null
            ? document.getElementById("relevancia").value
            : null,
        tipo_ambiente:
          document.getElementById("Tipo_Ambiente") !== null
            ? document.getElementById("Tipo_Ambiente").value
            : null,
        ip:
          document.getElementById("IP") !== null
            ? document.getElementById("IP").value
            : "",
        dns:
          document.getElementById("DNS") !== null
            ? document.getElementById("DNS").value
            : "",
        elemento_configuracion:
          document.getElementById("codigo_unico_aplicacion") !== null
            ? document.getElementById("codigo_unico_aplicacion").value
            : "",
        fechacreacion: today.toISOString(),
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentePrincipal: componentePrincipal,
      };
      console.log("data para post comp infra es", data);
      const objectToSend = JSON.stringify({
        ...data,
        ip: encryptData(data.ip),
        dns: encryptData(data.dns),
      });

      fetch(process.env.REACT_APP_API_URL + "onecomponent/0/", {
        method: "POST",
        body: objectToSend,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
              localStorage.setItem("idactivo", response.idactivo);
              history.push({
                pathname: "/EditarComponenteInfra", //Revisar //TODO
                state: "COM",
              });
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) {});
    }
    setValidated(true);
  };

  const ModalOtros = (props) => {
    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState("");
    const [compania, setCompania] = useState("");
    const [responsableNegocio, setResponsableNegocio] = useState("");
    const [clasificacionConfidencialidad, setClasificacionConfidencialidad] =
      useState("");
    const [clasificacionIntegridad, setClasificacionIntegridad] = useState("");
    const [clasificacionDisponibilidad, setClasificacionDisponibilidad] =
      useState("");

    const handleSave = () => {
      if (
        !nombre.trim() ||
        !compania.trim() ||
        !responsableNegocio.trim() 
        
      ) {
        alert("Por favor, complete todos los campos obligatorios.");
        return;
      }
      // Generar un nuevo ID (puedes cambiarlo según tus necesidades)
      const newId = rows.length + 1;

      // Crear la nueva fila
      const newRow = {
        idotros: newId,
        nombre,
        companias_otros: compania,
        UsuarioResponsableNegocio: responsableNegocio,
        clasificacionConfidencialidad: clasificacionConfidencialidad,
        clasificacionIntegridad: clasificacionIntegridad,
        clasificacionDisponibilidad: clasificacionDisponibilidad,
      };

      // Actualizar las filas de la tabla
      setRows([...rows, newRow]);

      // Limpiar el formulario
      setNombre("");
      setCompania("");
      setResponsableNegocio("");
      setClasificacionConfidencialidad("");
      setClasificacionIntegridad("");
      setClasificacionDisponibilidad("");
      props.onHide(); // Cierra el modal
    };
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Añadir Otras Aplicaciones
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Compañia</StyledTableCell>
                    <StyledTableCell align="left">
                      Responsable Negocio
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Confidencialidad
                    </StyledTableCell>
                    <StyledTableCell align="left">Integridad</StyledTableCell>
                    <StyledTableCell align="left">
                      Disponibilidad
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableCell>
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="Nombre"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                      required
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="Compañia"
                      value={compania}
                      onChange={(e) => setCompania(e.target.value)}
                      required
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="Responsable Negocio"
                      value={responsableNegocio}
                      onChange={(e) => setResponsableNegocio(e.target.value)}
                      required
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setClasificacionConfidencialidad(e.target.value)
                      }
                      id="confidencialidad"
                      required
                    >
                      <option value="">Seleccione la calificación...</option>
                      {clasificacion.map((campo) => (
                        <option key={campo.id} value={campo.nombre}>
                          {campo.nombre}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setClasificacionIntegridad(e.target.value)
                      }
                      id="integridad"
                      required
                    >
                      <option value="">Seleccione la calificación...</option>
                      {clasificacion.map((campo) => (
                        <option key={campo.id} value={campo.nombre}>
                          {campo.nombre}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setClasificacionDisponibilidad(e.target.value)
                      }
                      id="confidencialidad"
                      required
                    >
                      <option value="">Seleccione la calificación...</option>
                      {clasificacion.map((campo) => (
                        <option key={campo.id} value={campo.nombre}>
                          {campo.nombre}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <>{"Guardando..."}</>
            ) : (
              <Button
                className="botonPositivo"
                style={{ minWidth: "20%" }}
                onClick={handleSave}
              >
                Guardar
              </Button>
            )}
            <Button onClick={props.onHide} className="botonNegativo">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };
 
  const clasificacion = [
    { id: "1", nombre: "Alto" },
    { id: "2", nombre: "Medio" },
    { id: "3", nombre: "Bajo" },
  ];
  useEffect(() => {
    const obtenerComponentes = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "allcomponents/" +
          localStorage.getItem("idcompania") +
          "/1/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let datComponentes = await result.json();
        setModalCompo(datComponentes);
      }
    };
    obtenerComponentes();
  }, []);
  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const completarTabla = (obj, id) => {
      if (id === "detalles") {
        const temp = obj.map((item) =>
          modalCompo.find((dato) => dato.idactivo === item)
        ).filter(Boolean); // Filtra nulos
    
        // Si 'activos' es necesario, añade la propiedad
        temp.forEach((item) => {
          if (!item.activos) {
            item.activos = [item]; // Ajusta según el formato esperado
          }
        });
    
        console.log("Datos en rowsc después de procesar:", temp);
        setRowsC(temp);
        setModalShow(false);
      }
    };
    

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);

        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contTecno.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(contTecno, "detalles")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {modalCompo?.map((row, index) => {
                      const isItemSelected = isSelected(row.idactivo);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.idactivo,
                              row.idtipo_activo,
                              row
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.idactivo}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.idactivo}
                          </TableCell>
                          <TableCell align="left">{row.nombre}</TableCell>
                          <TableCell align="left">
                            {row.companias_usan}
                          </TableCell>
                          <TableCell align="left">
                            {row.UsuarioResponsableTI}
                          </TableCell>
                          <TableCell align="left">
                            {row.UsuarioResponsableNegocio}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={modalCompo.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  // Función para abrir el modales Carga - Otros - Clasificar
  const handleOpenModal = () => setShowModal(true);
  const handleOpenModalOtros = () => setShowModalOtros(true);
  const handleOpenModalClasificar = () => setShowModalClasificar(true);

  // Función para cerrar el modales  Carga - Otros - Clasificar
  const handleCloseModal = () => setShowModal(false);
  const handleCloseModalOtros = () => setShowModalOtros(false);
  const handleCloseModalClasificar = () => setShowModalClasificar(false);

  const [DataClasificacion, setDataClasificacion] = React.useState([]);
  const [DataPreguntas, setDataPreguntas] = React.useState([]);

  const ModalClasificar = (props) => {
    const [guardar, setLoading] = useState(false);
    console.log("ModalClasificar rendered");
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Clasificación de Aplicaciones
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table">
                <TableBody>
                  <InteractiveTable
                    idrol={idRol}
                    preguntas={DataPreguntas}
                    dataClasificacion={DataClasificacion}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} className="botonNegativo">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };
  const [hayPendientesApp, setHayPendientesApp] = useState(false);
  const [hayPendientesOtros, setHayPendientesOtros] = useState(false);

  useEffect(() => {
    if (activos || rowsc) {
      const hayPendientesApp = [...(activos || []), ...(rowsc || [])].some(
        (row) =>
          !row.clasificacion_confidencialidad ||
          !row.clasificacion_integridad ||
          !row.clasificacion_disponibilidad
      );
      setHayPendientesApp(hayPendientesApp);
    }
  }, [activos, rowsc]);

  useEffect(() => {
    if (otros || rows) {
      const hayPendientesOtros = [...(otros || []), ...(rows || [])].some(
        (row) =>
          !row.clasificacion_confidencialidad ||
          !row.clasificacion_integridad ||
          !row.clasificacion_disponibilidad 
      );
      setHayPendientesOtros(hayPendientesOtros);
    }
  }, [otros, rows]);

  return (
    <>
      <ModalCarga show={showModal} onHide={handleCloseModal} />
      <AlertDismissibleExample alerta={estadoPOST} />
      <MyVerticallyCenteredModal
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ModalClasificar
        show={showModalClasificar}
        onHide={handleCloseModalClasificar}
      />
      <ModalOtros show={showModalOtros} onHide={handleCloseModalOtros} />
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className="mb-3">
          <Col>
            {" "}
            <h2 className="subtitulo">Info General</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Id del informe*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre del informe"
              defaultValue={idInforme}
              required
              id="idInforme"
              readOnly
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Nombre del informe*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre del informe"
              defaultValue={nombreInforme}
              required
              id="NombreInforme"
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Descripción*</label>
          </Col>
          <Col sm={8} xs={12}>
            <textarea
              type="text"
              className="form-control text-center"
              placeholder="Descripcion de la reclasificacion"
              defaultValue={descripcion}
              rows="3"
              required
              id="Descripcion"
            ></textarea>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca una descripcion.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Programa*</label>
          </Col>
          <Col sm={8} xs={12}>
          <input
              type="text"
              className="form-control text-center"
              // el valor 1 se deja de prueba, se debe cambiar por el valor del programa
              value={idRol === 1 ? "Ciberseguridad" : idRol=== 2 ? "Riesgos" : "Auditoria"}
              required
              readOnly
              id="programa"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Experto*</label>
          </Col>
          <Col sm={8} xs={12}>
          <select
              className="form-control text-center"
              id="NombreExperto"
              required
            >
              <option value="">Seleccione un experto</option>
              {usuarios.map((usuario) => (
                <option key={usuario.id} value={usuario.id}>
                  {usuario.nombre} {usuario.apellido}
                </option>
              ))}
            </select>
            
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <br />
            <hr />
          </Col>
        </Row>

        <>
          <Row className="mb-3">
            <Col md={6}>
              <h2 className="subtitulo">Alcance de la prueba</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={9}>
              <h2 className="subtitulo">Aplicaciones*</h2>
            </Col>

            <Col md={2}>
              <Button
                className="botonIngreso"
                onClick={() => {
                setModalShow(true);
                }}
              >
                Añadir
              </Button>{" "}
            </Col>
          </Row>
          {hayPendientesApp && (
            <Row className="mb-3">
              <Col>
                <p style={{ color: "red" }}>
                  Existen aplicaciones con clasificaciones pendientes. Por
                  favor, revíselas.
                </p>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table" id="tablaAplicaciones">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">ID</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Compañia</StyledTableCell>
                    <StyledTableCell align="left">
                      Responsable TI
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Responsable Negocio
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Confidencialidad
                    </StyledTableCell>
                    <StyledTableCell align="left">Integridad</StyledTableCell>
                    <StyledTableCell align="left">
                      Disponibilidad
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(activos !== null || rowsc !== null) &&
                    [...(activos || []), ...(rowsc || [])].map((row, index) => {
                      const isItemSelected = isSelectedInfra(row.idactivo);
                      return (
                        <StyledTableRow
                          key={row.idactivo}
                          hover
                          onClick={(event) =>
                            handleClickInfra(event, row.idactivo)
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell align="left">
                            {row.idactivo}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.nombre}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.companias_usan ||
                              row.compania ||
                              "No Disponible"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.UsuarioResponsableTI ||
                              row.responsableti ||
                              "No Asignado"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.UsuarioResponsableNegocio ||
                              row.responsablenegocio ||
                              "No Asignado"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.clasificacion_confidencialidad ||
                              "No Clasificado"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.clasificacion_integridad || "No Clasificado"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.clasificacion_disponibilidad ||
                              "No Clasificado"}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Button
                              type="button"
                              onClick={() => {
                                setShowModalClasificar(true);
                                obtenerClasificacion(row.idactivo);
                              }}
                              className={"p-2 m-2"}
                              // disabled={
                              //   (row.clasificacion_confidencialidad &&
                              //     row.clasificacion_confidencialidad !==
                              //       "No Clasificado") ||
                              //   (row.clasificacion_integridad &&
                              //     row.clasificacion_integridad !==
                              //       "No Clasificado") ||
                              //   (row.clasificacion_disponibilidad &&
                              //     row.clasificacion_disponibilidad !==
                              //       "No Clasificado")
                              // }
                            >
                              Clasificar
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>

          <Row className="mb-3">
            <Col md={9}>
              <h2 className="subtitulo">Otros</h2>
            </Col>

            <Col md={2}>
              <Button onClick={handleOpenModalOtros} className="botonIngreso">
                Añadir Otros
              </Button>
            </Col>
          </Row>
          {hayPendientesOtros && (
            <Row className="mb-3">
              <Col>
                <p style={{ color: "red" }}>
                  Existen otras aplicaciones con clasificaciones pendientes. Por
                  favor, revíselas.
                </p>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table" id="tablaOtros">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">ID</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Compañia</StyledTableCell>
                    <StyledTableCell align="left">Responsable</StyledTableCell>
                    <StyledTableCell align="left">
                      Confidencialidad
                    </StyledTableCell>
                    <StyledTableCell align="left">Integridad</StyledTableCell>
                    <StyledTableCell align="left">
                      Disponibilidad
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(otros !== null || rows !== null) &&
                    [...(otros || []), ...(rows || [])].map((row) => {
                      const isItemSelected = isSelectedInfra(
                        row.idotros_activo || row.idotros
                      );
                      return (
                        <StyledTableRow
                          key={row.idotros_activo || row.idotros}
                          hover
                          onClick={(event) =>
                            handleClickInfra(
                              event,
                              row.idotros_activo || row.idotros
                            )
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            {row.idotros_activo || row.idotros}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.nombre}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.companias_usan ||
                              row.compania ||
                              row.companias_otros ||
                              "No Disponible"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.email_responsable ||
                              row.UsuarioResponsableNegocio ||
                              "No Asignado"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <select
                              className="form-control"
                              id="clasificacionConfidencialidad"
                              required
                            >
                              <option value="">
                                {row.clasificacion_confidencialidad ||
                                  row.clasificacionConfidencialidad ||
                                  "No Clasificado"}
                              </option>
                              {clasificacion.map((campo) => (
                                <option key={campo.id} value={campo.nombre}>
                                  {campo.nombre}
                                </option>
                              ))}
                            </select>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <select
                              className="form-control"
                              id="clasificacionIntegridad"
                              required
                            >
                              <option value="">
                                {row.clasificacion_integridad ||
                                  row.clasificacionIntegridad ||
                                  "No Clasificado"}
                              </option>
                              {clasificacion.map((campo) => (
                                <option key={campo.id} value={campo.nombre}>
                                  {campo.nombre}
                                </option>
                              ))}
                            </select>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <select
                              className="form-control"
                              id="clasificacionDisponibilidad"
                              required
                            >
                              <option value="">
                                {row.clasificacion_disponibilidad ||
                                  row.clasificacionDisponibilidad ||
                                  "No Clasificado"}
                              </option>
                              {clasificacion.map((campo) => (
                                <option key={campo.id} value={campo.nombre}>
                                  {campo.nombre}
                                </option>
                              ))}
                            </select>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              type="button"
                              // onClick={() => {
                              //   obtenerClasificacion(row.idactivo);
                              //   handleOpenModalClasificar(row);
                              // }}
                              className={"p-2 m-2"}
                              // disabled={
                              //   (row.clasificacion_confidencialidad &&
                              //     row.clasificacionConfidencialidad !==
                              //       "No Clasificado") ||
                              //   (row.clasificacion_integridad &&
                              //     row.clasificacionIntegridad !==
                              //       "No Clasificado") ||
                              //   (row.clasificacion_disponibilidad &&
                              //     row.clasificacionDisponibilidad !==
                              //       "No Clasificado")
                              // }
                            >
                              Clasificar
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>
        </>

        <Row className="mb-3">
          <Col>
            <br />
            <hr />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={9}>
            <h2 className="subtitulo">Hallazgos o debilidades*</h2>
          </Col>

          <Col md={2}>
            <Button className="botonDescargar" onClick={handleOpenModal}>
              Cargar
            </Button>
          </Col>
        </Row>

        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">ID</StyledTableCell>
                  <StyledTableCell align="left">Nombre</StyledTableCell>
                  <StyledTableCell align="left">Descripcion</StyledTableCell>
                  <StyledTableCell align="left">App</StyledTableCell>
                  <StyledTableCell align="left">Servidor</StyledTableCell>
                  <StyledTableCell align="left">Ip</StyledTableCell>
                  <StyledTableCell align="left">Clasificacion</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={5}></Col>
          <Col md={2}>
            <Button className="botonDescargar" onClick={() => ExportarExcel()}>
              Editar informe
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditarEthicalHacking;
