import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const DomainsChart = ({ info }) => {

  useEffect(() => {
    if (info.length === 0) return;


    const chartDom = document.getElementById("domainsChart");
    const myChart = echarts.init(chartDom);

    const nombresDominios = [];
    const porcentajesDominios = [];

    info.forEach((item) => {
      const dominios = item.dominios[0];
      Object.entries(dominios).forEach(([dominio, valores]) => {
        nombresDominios.push("Dominio " + dominio + "     ");
        porcentajesDominios.push(valores.porcentajeDominio);
      });
    });

    const getColorByPercentage = (percentage) => {
      if (percentage >= 96) {
        return "#04BF7B";
      } else if (percentage >= 86) {
        return "#7DF279";
      } else if (percentage >= 71) {
        return "#F2BF27";
      } else {
        return "#F27141";
      }
    };

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: "category",
        data: nombresDominios,
      },
      series: [
        {
          name: "Dominios",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            position: "insideRight",
          },
          itemStyle: {
           
            color: (params) => getColorByPercentage(params.data),
          },
          data: porcentajesDominios,
        },
        {
          name: "Línea limite",
          type: "line",
          markLine: {
              symbol: "none",
              lineStyle: {
                  color: "#FF0000",
                  width: 2,
              },
              data: [
                  {
                      xAxis: 71,
                  },
              ],
          },
      }
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [info]);

  return <div id="domainsChart" style={{ width: "100%", height: "510px" }}></div>;
};

export default DomainsChart;
