import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RiesgosPadre from './RiesgosPadre';
import DetalleRiesgos from './DetalleRiesgos';
//import VisualizacionRiesgos from './VisualizacionRiesgos';



const MainRiesgos = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Asociación a Efectos" style={{ color: "#ffffff" }} />
                    <Tab label="Detalle Riesgos" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    {/* <Tab label="Visualización" style={{ color: "#ffffff", padding: "3px 7px 3px" }} /> */}
                </Tabs>
            </AppBar>
            {value === 0 && <RiesgosPadre />}
            {value === 1 && <DetalleRiesgos  />}
            {/* {value === 2 && <VisualizacionRiesgos  />} */}
        </div>
    );
};

export default MainRiesgos;

