import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function M_Amenazas() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const classes = useStyles();
  const [filter, setFilter] = useState(false);

  const columns = [
    {
      title: "",
      field: "checked",
      width: '5%',
      render: (rowData) => (
        <Checkbox
          checked={rowData.checked}
          onChange={(event) => handleCheckboxChange(event, rowData)}
        />
      ),
    },
    {
      field: "idcapec",
      title: "ID",
      width: '10%',
    },
    {
      field: "evento_amenaza",
      title: "Amenaza",
      width: '30%',
    },
    {
      field: "disp_varchar1",
      title: "Descripción",
      width: '50%',
    },
  ];

  const [amenazas, setAmenazas] = React.useState([]);
  const [mitre, setMitre] = React.useState([]);
  const [techniques, setTechniques] = React.useState([]);
  const [mitigations, setMitigations] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [capecmitreselected, setCapecmitreselected] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ButtonVer, SetButtonVer] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [nombre, setNombre] = useState("");
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  
  useEffect(() => {
    const fetchamenazas = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "maestro/amenazas/0",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setAmenazas(data);
    };

    const fetchmitre = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "maestro/mitre/0",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setMitre(data);
    };

    fetchamenazas();
    fetchmitre();
  }, []);

  useEffect(() => {
    if (open && capecmitreselected !== null) {
      const fetchTechnique = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL + "maestro/mitre/" + capecmitreselected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let data = await result.json();
        console.log("data capec", data.capec);
        setTechniques(data.techniques || []);
        setMitigations(data.mitigations || []);

        if (data.capec[0].capec_name !== null) {
          setNombre(data.capec[0].capec_name);
        }
      };
      fetchTechnique();
    } else {
      setTechniques([]);
      setMitigations([]);
    }
  }, [open, capecmitreselected]);

  const handleChange = () => {
    setFilter(!filter);
  };

  const renderRelatedTechniques = (rowData) => {
    const ids = rowData.related_techniques.ID_ATTCK || [];
    const names = rowData.related_techniques.Name_ATTCK || [];

    const combinedData = ids.map((id, index) => ({
      id: id,
      name: names[index],
    }));

    return (
      <div>
        {combinedData.map((item, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <p>
              <strong>ID:</strong> {item.id}
            </p>
            <p>
              <strong>Nombre:</strong> {item.name}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonVer(true);
    } else {
      SetButtonVer(false);
    }
    setSelected(newSelected);
  };

  const ver = () => {
    localStorage.setItem("idcapec", selected);
  };

  const handleClickMitre = (event, row) => {
    console.log("row", row);
    setCapecmitreselected(
      capecmitreselected === row.capec_name ? null : row.capec_id
    );
    setOpen(true);
  };

  const isSelected = (capec_id) => capecmitreselected === capec_id;

  const handleClose = () => {
    setOpen(false);
    setCapecmitreselected(null);
    setNombre("");
    setTechniques([]);
    setMitigations([]);
  };

  const [data, setData] = useState([false]);

  const handleCheckboxChange = (event, rowData) => {
    const newData = data.map((item) => {
      if (item.name === rowData.name) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setData(newData, true);
  };

  const handleRowClick = (event, rowData) => {
    const newData = data.map((item) => ({
      ...item,
      checked: item.name === rowData.name ? !item.checked : false,
    }));
    setData(newData);
  };

  return (
    <div>
      <Row>
        <Col>
          <h1>Taxonomía de Amenazas</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ zIndex: 100 }}>
            <Paper variant="outlined" className={classes.paper}>
              <MaterialTable
                columns={columns}
                data={amenazas}
                icons={tableIcons}
                options={{
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      ButtonVer === true && selectedRow === rowData.idcapec
                        ? "#ffdb94"
                        : "#FFF",
                  }),
                  showTitle: false,
                  filtering: filter,
                  searchFieldAlignment: "left",
                  searchFieldStyle: { height: 50 },
                  //zIndex: -1000,
                  headerStyle: {
                    //zIndex: 1,
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "relative",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "650px",
                  selection: false,
                  disableMultipleSelection: true,
                  pageSizeOptions: [10, 20, 30],
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  pageSize: 15,
                }}
                components={{
                  Pagination: (props) => (
                    <div className={classes.pagination}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Checkbox
                            checked={filter}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Mostrar filtros por columnas
                          </Typography>
                        }
                      />
                      <TablePagination
                        {...props}
                        className={classes.pagination}
                      />
                    </div>
                  ),
                }}
                onRowClick={(evt, selectedRow) => {
                  handleCheckboxChange(evt, selectedRow);
                  handleClick(selectedRow.idcapec);
                  return setSelectedRow(selectedRow.idcapec);
                }}
                actions={[
                  {
                    icon: () => (
                      <Link to="EditarAmenazas">
                        <Button className="botonGeneral">Ver</Button>
                      </Link>
                    ),
                    onClick: ver(),
                    position: "toolbar",
                    isFreeAction: true,
                    hidden: ButtonVer === false,
                  },
                ]}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
              />
            </Paper>
          </div>
        </Col>
      </Row>
      <br />
      <hr />
      <br />
      <Row>
        <Col>
          <h1>Amenazas - Técnicas MITRE ATT&CK</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableContainer component={Paper} className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              maxHeightBody="55vh"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow className={classes.head}>
                  <StyledTableCell padding="checkbox"></StyledTableCell>
                  <StyledTableCell>Id CAPEC</StyledTableCell>
                  <StyledTableCell>Nombre</StyledTableCell>
                  <StyledTableCell>Abstracción</StyledTableCell>
                  <StyledTableCell>Técnicas</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mitre.map((row) => {
                  const isItemSelected = isSelected(row.capec_id);
                  const labelId = `enhanced-table-checkbox-${row.capec_id}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClickMitre(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.capec_id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.capec_id}</StyledTableCell>
                      <StyledTableCell>{row.capec_name}</StyledTableCell>
                      <StyledTableCell>{row.capec_abstraction}</StyledTableCell>
                      <StyledTableCell>
                        {renderRelatedTechniques(row)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* Crear modal basico */}
            <Modal
              size="xl"
              show={open}
              onHide={handleClose}
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Detalles de las técnicas
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="grid-example">
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Id CAPEC</label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={capecmitreselected}
                    ></input>
                  </Col>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Nombre</label>
                  </Col>
                  <Col sm={6} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={nombre}
                    ></input>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col sm={12} xs={12}>
                    <label className="label forn-label">
                      Técnicas o subtécnicas
                    </label>
                  </Col>
                  <Col>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow className={classes.head}>
                            <StyledTableCell padding="checkbox"></StyledTableCell>
                            <StyledTableCell>ID Técnica</StyledTableCell>
                            <StyledTableCell>Nombre</StyledTableCell>
                            <StyledTableCell>Descripción</StyledTableCell>
                            <StyledTableCell>Plataformas</StyledTableCell>
                            <StyledTableCell>Link</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {techniques.map((row) => (
                            <StyledTableRow>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>
                                {row.id_technique}
                              </StyledTableCell>
                              <StyledTableCell>{row.name}</StyledTableCell>
                              <StyledTableCell>
                                {row.description}
                              </StyledTableCell>
                              <StyledTableCell>
                                <ul>
                                  {row.x_mitre_platforms.map((platform) => (
                                    <li>{platform}</li>
                                  ))}
                                </ul>
                              </StyledTableCell>
                              <StyledTableCell>
                                <a
                                  href={row.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Ver más detalles
                                </a>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={12} xs={12}>
                    <label className="label forn-label">Mitigaciones</label>
                  </Col>
                  <Col>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow className={classes.head}>
                            <StyledTableCell padding="checkbox"></StyledTableCell>
                            <StyledTableCell>ID Técnica</StyledTableCell>
                            <StyledTableCell>ID Mitigación</StyledTableCell>
                            <StyledTableCell>Nombre</StyledTableCell>
                            <StyledTableCell>Descripción</StyledTableCell>
                            <StyledTableCell>Link</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(mitigations).map((techniqueId) =>
                            mitigations[techniqueId].map(
                              (mitigation, index) => (
                                <StyledTableRow key={`${techniqueId}-${index}`}>
                                  <StyledTableCell padding="checkbox"></StyledTableCell>
                                  <StyledTableCell>
                                    {techniqueId}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {mitigation.id_mitigation}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {mitigation.name}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {mitigation.description}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <a
                                      href={mitigation.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Ver más detalles
                                    </a>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </TableContainer>
        </Col>
      </Row>
    </div>
  );
}

export default M_Amenazas;
