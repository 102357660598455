import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const DomainChartComponent = ({ dominios }) => {
    const [selectedNombrecis, setSelectedNombrecis] = useState(dominios.length > 0 ? dominios[0].nombrecis : "");

    const getColorByPercentage = (percentage) => {
        if (percentage >= 96) {
          return "#04BF7B"; //  96-100
        } else if (percentage >= 86) {
          return "#7DF279"; //  86-95
        } else if (percentage >= 71) {
          return "#F2BF27"; // 71-85
        } else {
          return "#F27141"; //  0-70
        }
      };


    useEffect(() => {
        const chartDom = document.getElementById("main");
        const myChart = echarts.init(chartDom);

        const selectedDominios = dominios.find(d => d.nombrecis === selectedNombrecis).dominios;
        const yAxisData = selectedDominios.map(d => Object.keys(d)[0]);
        const xAxisData = selectedDominios.map(d => Object.values(d)[0].porcentajeDominio);

        const option = {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: {
                type: "value",
                boundaryGap: [0, 0.01],
            },
            yAxis: {
                type: "category",
                data: yAxisData,
            },
            series: [
                {
                    name: "Dominios",
                    type: "bar",
                    stack: "total",
                    label: {
                        show: true,
                        position: "insideRight",
                    },
                    itemStyle: {
                        color: (params) => getColorByPercentage(params.data),
                    },
                    data: xAxisData,
                },
                {
                    name: "Línea Vertical",
                    type: "line",
                    markLine: {
                        symbol: "none",
                        lineStyle: {
                            color: "#FF0000", 
                            width: 2,
                        },
                        data: [
                            {
                                xAxis: 71, 
                            },
                        ],
                    },
                },
            ],
        };

        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [selectedNombrecis, dominios]);

    return (
        <div>
            <select onChange={(e) => setSelectedNombrecis(e.target.value)} value={selectedNombrecis}>
                {dominios.map((d) => (
                    <option key={d.nombrecis} value={d.nombrecis}>
                        {d.nombrecis}
                    </option>
                ))}
            </select>
            <div id="main" style={{ width: "100%", height: "510px" }}></div>
        </div>
    );
};

export default DomainChartComponent;