import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const StackedLineEfectividadChart = ({ historicoefectividad, getColor, tipo }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (historicoefectividad.length === 0) return;
    if (!chart) {
      const chartDom = document.getElementById("main_efectividad_chart");
      const myChart = echarts.init(chartDom);
      setChart(myChart);
    }

    if (tipo === "ero") {

      if (chart) {
        const seriesData = historicoefectividad.map((item, index) => ({
          name: `Riesgo ID :${item.idriesgo}`,
          type: "line",
          data: item.efectividadcontrol,
          lineStyle: {
            color: getColor(index),
          },
          itemStyle: {
            color: getColor(index),
          },
        }));
        const dates = historicoefectividad[0].fechaCreacion.map((date) =>
          new Date(date).toLocaleDateString()
        );

        const option = {
          title: {
            text: "Efectividad del Control por Riesgo",
            left: "center",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: seriesData.map((item) => item.name),
            top: 30,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: dates,
          },
          yAxis: {
            type: "value",
          },
          series: seriesData,
        };

        chart.setOption(option);
      }

    } 
    if (tipo === "resumenefectividad") {

      if (chart) {
        const seriesData = historicoefectividad.map((item, index) => ({
          name: `Riesgo ID :${item.idriesgo}`,
          type: "line",
          data: item.historico.map(h => h.efectividad),
          lineStyle: {
            color: getColor(index),
          },
          itemStyle: {
            color: getColor(index),
          },
        }));
        const dates = historicoefectividad[0].historico.map((item) =>
          new Date(item.fechacreacion).toLocaleDateString()
        );


        const option = {
          title: {
            text: "Histórico de Efectividad por Riesgo",	
            left: "center",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: seriesData.map((item) => item.name),
            top: 30,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: dates,
          },
          yAxis: {
            type: "value",
          },
          series: seriesData,
        };


        chart.setOption(option);
      }
    } 

    return () => {
      chart && chart.dispose();
    };
  }, [historicoefectividad, chart]);

  return (
    <div
      id="main_efectividad_chart"
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default StackedLineEfectividadChart;
