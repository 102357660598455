import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const DominiosLineamientosChart = ({ info }) => {
  const [dominiosKeys, setDominiosKeys] = useState([]);
  const [selectedDominio, setSelectedDominio] = useState("");
  const [lineamientoData, setLineamientoData] = useState([]);
  const [lineamientosLabels, setLineamientosLabels] = useState([]);


  useEffect(() => {
    if (info.length > 0) {
      const firstDominios = info[0]?.dominios[0];
      if (firstDominios) {
        const keys = Object.keys(firstDominios);
        setDominiosKeys(keys);
        setSelectedDominio(keys[0]);
      }
    }
  }, [info]);

  useEffect(() => {
    if (info.length > 0 && selectedDominio) {
      const data = [];
      const labels = [];

      info.forEach((item) => {
        const dominio = item.dominios[0]?.[selectedDominio];
        if (dominio && dominio.lineamientos) {
            dominio.lineamientos.forEach((lineamiento) => {
            const key = Object.keys(lineamiento)[0];
            const porcentaje = Number(lineamiento[key]);
            data.push(porcentaje);
            labels.push("Lineamiento " + key + "     ");
            });
        }
      });

      setLineamientoData(data);
      setLineamientosLabels(labels);
    }
  }, [info, selectedDominio]);

  useEffect(() => {
    const chartDom = document.getElementById("chart");
    const myChart = echarts.init(chartDom);

    const getColor = () => {
      const colors = [
        "#04BF7B",
        "#7DF279",
        "#F2BF27",
        "#F27141",
        "#FF5733",
        "#C70039",
        "#900C3F",
        "#581845",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    };
    const option = {
      tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      xAxis: { type: "value", boundaryGap: [0, 0.01] },
      yAxis: {
        type: "category",
        data: lineamientosLabels,
      },
      series: [
        {
          name: "Porcentaje",
          type: "bar",
          label: { show: true, position: "insideRight" },
          itemStyle: { color: (params) => getColor(params.data) },
          data: lineamientoData,
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [lineamientoData, lineamientosLabels]);

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="dominios-select">Selecciona un Dominio:</label>
        <select
          id="dominios-select"
          style={{ marginLeft: "10px" }}
          onChange={(e) => setSelectedDominio(e.target.value)}
          value={selectedDominio}
        >
          {dominiosKeys.map((key) => (
            <option value={key} key={key}>
              {"Dominio " + key}
            </option>
          ))}
        </select>
      </div>

      <div id="chart" style={{ width: "100%", height: "510px" }}></div>
    </div>
  );
};

export default DominiosLineamientosChart;
